export const CHAINS = {
    1: {
        "name": "Ethereum Mainnet",
        "chainId": 1, 
        "symbol": "ETH",
        "decimals": 18,
        "rpcUrl":[ "https://ethereum-rpc.publicnode.com"],
        "blockExplorer": "https://etherscan.io",

        "uniswapV2": {
            "router": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            "factory": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"
        },

        "fairLaunch": {
            "creator": "0x0000000000000000000000000000000000000000"
        }
    }, 
    56: {
        "name": "Binance Smart Chain",
        "chainId": 56,
        "symbol": "BNB",
        "decimals": 18,
        "rpcUrl": ["https://bsc-dataseed.binance.org"],
        "blockExplorer": "https://bscscan.com",

        "uniswapV2": {
            "router": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
            "factory": "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"
        },

        "fairLaunch": {
            "creator": "0x0000000000000000000000000000000000000000"
        }
    },
    8453 : {
        "name": "Base",
        "chainId": 8453,
        "decimals": 18,
        "symbol": "Base-ETH",
        "rpcUrl": ["https://base-rpc.publicnode.com"],
        "blockExplorer": "https://basescan.org",

        "uniswapV2" : {
            "router": "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24",
            "factory": "0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6"
        },

        "fairLaunch": {
            "creator": "0x0000000000000000000000000000000000000000"
        }
    },

    11155111: {
        "name": "Sepolia Testnet",
        "chainId": 11155111,
        "symbol": "testETH",
        "decimals": 18,
        "rpcUrl": ["https://ethereum-sepolia-rpc.publicnode.com"],
        "blockExplorer": "https://sepolia.etherscan.io",

        "uniswapV2": {
            "router": "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            "factory": "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"
        },

        "fairLaunch": {
            "creator": "0x3dCfeC4D0e3785C899EE6EeD862c6285046c11FA"
        }
    },

    97 : {
        "name": "BSC Testnet",
        "chainId": 97,
        "symbol": "testBNB",
        "decimals": 18,
        "rpcUrl": ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        "blockExplorer": "https://testnet.bscscan.com",

        "uniswapV2": {
            "router": "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
            "factory": "0x6725F303b657a9451d8BA641348b6761A6CC7a17"
        },

        "fairLaunch": {
            "creator": "0x0000000000000000000000000000000000000000"
        }
    }

}