import React from 'react';
import { Badge, Button, Card, Form, InputGroup } from 'react-bootstrap';
import {ethers} from 'ethers';
import { conn, connect, isInWeb3Browser, onAccountChange, onChainSwitch, switchNetwork, toEIP55 } from '../api/dapp';
import { CHAINS } from '../config/chains';

const ABI = [
  'function deploy( uint256 salt,\
    uint256 _price, \
    uint256 _amountPerUnits, \
    uint256 totalSupply, \
    address _launcher, \
    address _uniswapRouter, \
    address _uniswapFactory, \
    string memory _name, \
    string memory _symbol, \
    uint256 _eachAddressLimitEthers \
  ) public' ,
  
  'function getContractAddress( \
      uint256 salt, \
      uint256 _price, \
      uint256 _amountPerUnits, \
      uint256 totalSupply, \
      address _launcher, \
      address _uniswapRouter, \
      address _uniswapFactory, \
      string memory _name, \
      string memory _symbol, \
      uint256 _eachAddressLimitEthers \
    ) public view returns (address)'
]

const CONSTRACTOR_ABI = [
  'constructor( \
    uint256 _price, \
    uint256 _amountPerUnits, \
    uint256 totalSupply, \
    address _luncher, \
    address _uniswapRouter, \
    address _uniswapFactory, \
    string memory _name, \
    string memory _symbol, \
    uint256 _eachAddressLimitEthers \
  )'
]

class ACreatePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      address: '',
      chainName: 'Not connected',
      chainId: 'Not connected',

      chainIdSelected: '1',

      price: '0.001',
      amountPerUnits: '30000',
      totalSupply: '19999980000',

      ethersLimitPerAccount: '1',
      salt: '1',

      tokenName: '',
      tokenSymbol: '',

      createArgeABI: null,

      showResult: false,
      predictAddress: '',
    };
  }

  componentDidMount() {
    this.init();
  }

  doDeploy = async () => {
    const { provider, chainId } = await connect()
    const signer = await provider.getSigner()

    const chain = CHAINS[chainId]

    const contract = new ethers.Contract(chain.fairLaunch.creator, ABI, signer)
    console.log('creator:', chain.fairLaunch.creator)

    const price = ethers.parseEther(this.state.price)
    const amountPerUnits = ethers.parseEther(this.state.amountPerUnits)
    const totalSupply = ethers.parseEther(this.state.totalSupply)
    const ethersLimitPerAccount = ethers.parseEther(this.state.ethersLimitPerAccount)

    const tokenContractAddress = await contract.getContractAddress(
      this.state.salt,
      price,
      amountPerUnits,
      totalSupply,
      this.state.address,
      chain.uniswapV2.router,
      chain.uniswapV2.factory,
      this.state.tokenName,
      this.state.tokenSymbol,
      ethersLimitPerAccount,
      provider
    )

    console.log('tokenContractAddress:', tokenContractAddress)
    
    // generate the create ABI
    const iface = new ethers.Interface(CONSTRACTOR_ABI);
    const data = iface.encodeDeploy([
      price,
      amountPerUnits,
      totalSupply,
      this.state.address,
      chain.uniswapV2.router,
      chain.uniswapV2.factory,
      this.state.tokenName,
      this.state.tokenSymbol,
      ethersLimitPerAccount
    ]);

    console.log("Constructor ABI Data:", data);

    const tx = await contract.deploy(
      this.state.salt,
      price,
      amountPerUnits,
      totalSupply,
      this.state.address,
      chain.uniswapV2.router,
      chain.uniswapV2.factory,
      this.state.tokenName,
      this.state.tokenSymbol,
      ethersLimitPerAccount,
      signer
    )

    // setstate createArgeABI
    this.setState({
      createArgeABI: data,
      showResult: true,
      predictAddress: tokenContractAddress,
    });

    // jump to explorer to check the transaction
    // window.open(chain.blockExplorer + '/tx/' + tx.hash, '_blank')
    // document.location.href = chain.blockExplorer + '/tx/' + tx.hash
  }


  init = async () => {

    if (!isInWeb3Browser()) {
      alert('Please install Metamask Or open In Metamask/TokenPocket APP');
      return;
    }

    try {
      const { provider, chainId , address } = await connect()

      if (CHAINS[chainId] === undefined) {
        // not support
        alert('Chain not supported:' + chainId)
        return;
      }

      console.log('chainId:', chainId)
      this.setState({
        address: toEIP55(address),
        chainId,
        chainName: CHAINS[chainId].name,
        chainIdSelected: chainId + ''
      });

      onAccountChange({
        success: this._onAccountChange
      })

      onChainSwitch({
        success: this._onChainSwitch
      })

    } catch (error) {
      console.error(error)
    }  
  }

  _onAccountChange = async (accounts) => {
  }

  _onChainSwitch = async (chainId) => {
  }

  _onSelectChainInForm = async (e) => {
    this.setState({chainIdSelected: e.target.value});
    // get the current chain id , if not equal to the selected chain id, switch the chain
    const { chainId } = await conn()
    if (chainId != e.target.value) {

      const chain = CHAINS[e.target.value]
      switchNetwork(chain, () => {
        this.init()
      })
    }
  }


  render() {
    return (
      <div style={{
        margin: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div style={{
          maxWidth: '600px',
          width: '100%',
        }}>
          <div style={{
            position: 'relative',
          }}>
            <img src="https://pbs.twimg.com/profile_banners/1769929370653585408/1711083425/1500x500" alt="Logo" style={{
              width: '100%',
              borderRadius: '0.5rem',
            }} />
            <img src="https://pbs.twimg.com/profile_images/1771134544751583232/i1vmU9pH_400x400.jpg" alt="Logo" style={{
              width: '5rem',
              borderRadius: '5rem',
              position: 'absolute',
              left: '2rem',
              top: '7rem',
            }} />
          </div>
          <div style={{ marginTop: '1rem' }}>
            <Card>
              <Card.Header as="h5" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}>
                <div>
                  <Form.Select aria-label="Target Chain" value={this.state.chainIdSelected} onChange={(e)=> {
                    this._onSelectChainInForm(e);
                  }}>
                    {Object.keys(CHAINS).map((key) => {
                      return <option key={key} value={key}>{CHAINS[key].name} (ID:{CHAINS[key].chainId})</option>
                    })}
                  </Form.Select>
                </div>
                <img src={require('../assets/icons/info.png')} alt="Information" style={{
                  width: '1.2rem',
                  height: '1.2rem',
                }} />
              </Card.Header>
              <Card.Body>
                <h5><Badge bg="primary">{this.state.chainName} (ID: {this.state.chainId + ''})</Badge></h5>
                <div>
                {this.state.address? <h5 ><Badge bg="success">Current: {this.state.address.substring(0, 10) + '...' + this.state.address.substring(this.state.address.length - 10)
                }</Badge></h5>: null }
                </div>
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <Card>
              <Card.Body>
                <Form>

                  

                  <Form.Label>Minimum investment amount  <AHelpIcon /> </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="price"
                      aria-label="price"
                      aria-describedby="basic-addon2"
                      value={this.state.price}
                      onChange={(e) => {
                        this.setState({price: e.target.value});
                      }}
                      maxLength={18}
                      minLength={1}
                      isValid={this.state.price > 0 && this.state.price < 9999999999}
                      isInvalid={this.state.price <= 0 || this.state.price >= 9999999999}
                    />
                    <InputGroup.Text id="basic-addon2"> ethers</InputGroup.Text>
                  </InputGroup>

                  <Form.Label>How many tokens can users get for each payment? <AHelpIcon />  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="amount"
                      aria-label="amount"
                      aria-describedby="basic-addon2"
                      value={this.state.amountPerUnits}
                      onChange={(e) => {
                        this.setState({amountPerUnits: e.target.value});
                        }
                      }
                      maxLength={18}
                      minLength={1}
                      isValid={this.state.amountPerUnits > 0 && this.state.amountPerUnits < 9999999999}
                      isInvalid={this.state.amountPerUnits <= 0 || this.state.amountPerUnits >= 9999999999}
                    />
                    <InputGroup.Text id="basic-addon2"> x 10 ^ 18</InputGroup.Text>
                  </InputGroup>

                  <Form.Label>Token name <AHelpIcon /> </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="name"
                      aria-label="name"
                      aria-describedby="basic-addon2"
                      value={this.state.tokenName}
                      onChange={(e) => {
                        this.setState({tokenName: e.target.value});
                      }}
                      maxLength={20}
                      minLength={2}

                      isValid={/^[a-zA-Z0-9_\\-\\ ]{2,20}$/.test(this.state.tokenName)}
                      isInvalid={!/^[a-zA-Z0-9_\\-\\ ]{2,20}$/.test(this.state.tokenName)}
                    />
                  </InputGroup>
                  <Form.Label>Symbol <AHelpIcon /> </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="symbol"
                      aria-label="symbol"
                      aria-describedby="basic-addon2"
                      value={this.state.tokenSymbol}
                      onChange={(e) => {
                        this.setState({tokenSymbol: e.target.value});
                      }}
                      maxLength={20}
                      minLength={2}

                      isValid={/^[a-zA-Z0-9_\\-]{2,20}$/.test(this.state.tokenSymbol)}
                      isInvalid={!/^[a-zA-Z0-9_\\-]{2,20}$/.test(this.state.tokenSymbol)}
                    />
                  </InputGroup>

                  

                  <Form.Label>Total Supply <AHelpIcon />  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Total Supply"
                      aria-label="Total Supply"
                      aria-describedby="basic-addon2"
                      value={this.state.totalSupply}
                      onChange={(e) => {
                        this.setState({totalSupply: e.target.value});
                      }
                      }
                      maxLength={18}
                      minLength={1}
                      isValid={this.state.totalSupply > 0 && (this.state.totalSupply+'').length < 18}
                      isInvalid={this.state.totalSupply <= 0 || (this.state.totalSupply+'').length >= 18}
                    />
                    <InputGroup.Text id="basic-addon2"> x 10 ^ 18</InputGroup.Text>
                  </InputGroup>

                  <Form.Label>Launcher <AHelpIcon />  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Launcher"
                      aria-label="Launcher"
                      aria-describedby="basic-addon2"
                      value={this.state.address}
                      onChange={(e) => {
                        this.setState({address: e.target.value});
                      }}

                      maxLength={42}
                      minLength={42}

                      isValid={/^(0x)?[0-9a-fA-F]{40}$/.test(this.state.address)}
                      isInvalid={!/^(0x)?[0-9a-fA-F]{40}$/.test(this.state.address)}
                    />
                  </InputGroup>

                  <Form.Label>Each account can buy <AHelpIcon />  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="ethers limit per account"
                      aria-label="ethers limit per account"
                      aria-describedby="basic-addon2"
                      type="number"
                      value={this.state.ethersLimitPerAccount}
                      onChange={(e) => {
                        this.setState({ethersLimitPerAccount: e.target.value});
                      }}
                      
                      maxLength={18}
                      minLength={1}
                      isValid={this.state.ethersLimitPerAccount > 0 && (this.state.ethersLimitPerAccount+'').length < 18}
                    />
                    <InputGroup.Text id="basic-addon2"> ethers</InputGroup.Text>
                  </InputGroup>

                  <Form.Label>Salt <AHelpIcon />  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Salt"
                      aria-label="Salt"
                      aria-describedby="basic-addon2"
                      type="number"
                      value={this.state.salt}

                      onChange={(e) => {
                        this.setState({salt: e.target.value});
                      }
                      }

                      maxLength={18}
                      minLength={1}

                      isValid={this.state.salt > 0 && (this.state.salt+'').length < 18}
                    />
                  </InputGroup>

                </Form>
              </Card.Body>
            </Card>
            <div className="d-grid gap-2" style={{ marginTop: '1rem' }}>
              {/* 背景是从#86EFEF到#A9FB7D过渡色 */}
              <Button style={{
                backgroundImage: "linear-gradient(45deg, #86EFEF, #A9FB7D)",
                color: "black",
                border: "none",
              }} variant="primary" size="lg" onClick={(e) => {
                this.doDeploy();
              }}>
                Deploy Token
              </Button>
            </div>
            {/* -- end of button */}
          </div>
          {/* --end of form items */}
          <div style={{ marginTop: '1rem' }}>
            <Card>
                <Card.Body>
                  <div>Download the token contract source code from <a href="#">
                    Github
                    </a> to verify the contract code on Etherscan.
                  </div>
                  <div>
                    <a href="#">Click Here</a> to open Etherscan
                  </div>
                </Card.Body>
            </Card>
          </div>
        </div>
            {/* show result dialog  */}
            {this.state.showResult ? <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}>
              <Card style={{
                width: '80%',
                maxWidth: '600px',
              }}>
                <Card.Header>
                  <h5>Deploy Result</h5>
                </Card.Header>
                <Card.Body>
                  <div>
                    <Form.Label>Contract Address {this.state.chainId && CHAINS[this.state.chainId]? <a href={
                      CHAINS[this.state.chainId].blockExplorer + '/token/' + this.state.predictAddress
                    } target="_blank" rel="noreferrer">View Contract on Etherscan</a>: null }</Form.Label> 
                    <InputGroup className="mb-3"> 
                      <Form.Control
                        placeholder="Contract Address"
                        aria-label="Contract Address"
                        aria-describedby="basic-addon2"
                        value={this.state.predictAddress}
                        readOnly
                      />
                    </InputGroup>
                    <Form.Label>Constructor ABI (For code verifing)</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Constructor ABI"
                        aria-label="Constructor ABI"
                        aria-describedby="basic-addon2"
                        value={this.state.createArgeABI}
                        as="textarea"
                        rows={5}
                        readOnly
                      />
                    </InputGroup>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" onClick={(e) => {
                    this.setState({
                      showResult: false,
                    });
                  }}>Close</Button>
                </Card.Footer>
              </Card>
          </div>: null }
      </div>
    );
  }
}

class AHelpIcon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <img src={require('../assets/icons/help.png')} alt="Information" style={{
        width: '1.2rem',
        height: '1.2rem',
        marginBottom: '0.1rem',
      }} />
    );
  }

}

function CreatePage() {
  return <ACreatePage />;
}

export default CreatePage;